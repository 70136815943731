<template>
  <section class="VSectionCloud section-profile section-cloud">
    <h2 class="section-profile__title">
      Подключение к сервису
    </h2>

    <Form
      v-slot="{ errors }"
      class="section-profile__form"
      @submit="checkForm"
    >
      <!-- Сообщение об ошибке если что то пошло не так -->
      <vAlertMessage v-if="USER_ERROR.length > 0">
        {{ USER_ERROR }}
      </vAlertMessage>

      <fieldset class="section-profile__group">
        <!-- Телефон -->
        <vInput
          id="phone"
          v-model="phone"
          :value="phone"
          placeholder="Телефон"
          :required="true"
          rule="phone"
          :error="validation.PhoneNumber"
          :disabled="isSavedPhone"
          @input="setInitialErrors"
        />

        <!-- Номер карты-->
        <div
          v-if="hasNumberCard"
          class="section-cloud__card-wrap"
        >
          <div class="section-cloud__card-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3427_91194)">
                <path
                  d="M5.55796 9.763L5.27096 8.696C4.98196 7.627 5.61696 6.528 6.68696 6.243L16.73 3.567C17.79 3.285 18.89 3.92 19.176 4.979L20.93 11.488C21.218 12.557 20.582 13.656 19.514 13.941L17.4 14.504"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 14.077H17.4M5 20.737H15.4C16.505 20.737 17.4 19.842 17.4 18.737V12C17.4 10.895 16.505 10 15.4 10H5C3.895 10 3 10.895 3 12V18.737C3 19.842 3.896 20.737 5 20.737V20.737Z"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3427_91194">
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div class="section-cloud__card-text">
            {{ CLOUD_DATA.card_last_four }}
          </div>

          <div
            class="section-cloud__card-delete"
            @click="deleteCardModal = true"
          >
            Удалить
          </div>
        </div>

        <legend class="section-profile__legend section-cloud__legend">
          <h3>Номер телефона</h3>
          <p v-if="isSavedPhone">
            Для смены номера телефона необходимо написать письмо на почту:
            <a href="mailto:Support@cp.ru">Support@cp.ru</a>
          </p>
          <p v-else>
            Введите номер для привязки сервиса
          </p>
        </legend>
      </fieldset>

      <vButton
        v-if="!isSavedPhone"
        type="submit"
        :disabled="
          Object.keys(errors).length > 0 ||
            errorMessage.length > 0 ||
            phone === null ||
            phone.length !== 17
        "
        @click.prevent="savePhone"
      >
        Применить
      </vButton>

      <fieldset class="section-profile__group">
        <vButton
          v-if="!hasNumberCard"
          type="button"
          :disabled="!isSavedPhone"
          @click="cardModal = true"
        >
          Привязать карту
        </vButton>

        <legend class="section-profile__legend section-cloud__legend">
          <h3>Банковская карта</h3>
          <p>На привязанную карту будут поступать чаевые</p>
        </legend>
      </fieldset>
    </Form>

    <div class="section-profile__form section-cloud__form">
      <fieldset class="section-profile__group">
        <div class="section-cloud__item vLandingInput">
          <span class="section-cloud__key">
            Оплата издержек за счет клиента
          </span>

          <span class="section-cloud__value"> 0 рублей </span>
        </div>

        <div class="section-cloud__item vLandingInput">
          <span class="section-cloud__key">
            Оплата издержек за счет официанта
          </span>

          <span class="section-cloud__value"> 5% </span>
        </div>

        <legend class="section-profile__legend section-cloud__legend">
          <h3>Комиссии за чаевые</h3>
          <p>Для карт Tinkoff</p>
        </legend>
      </fieldset>
      <fieldset class="section-profile__group">
        <div class="section-cloud__item vLandingInput">
          <span class="section-cloud__key">
            Оплата издержек за счет клиента
          </span>

          <span class="section-cloud__value"> 30 рублей </span>
        </div>

        <div class="section-cloud__item vLandingInput">
          <span class="section-cloud__key">
            Оплата издержек за счет официанта
          </span>

          <span class="section-cloud__value"> 7% + 30 рублей </span>
        </div>

        <legend class="section-profile__legend section-cloud__legend">
          <h3>Комиссии за чаевые</h3>
          <p>Для карт других банков</p>
        </legend>
      </fieldset>

      <div class="info">
        <div class="info__icon">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 17.5V12.5H11M11.9119 8.55972C11.9659 8.60559 12 8.67383 12 8.75C12 8.79461 11.9883 8.8365 11.9678 8.87276M11.9119 8.55972C11.8681 8.52249 11.8113 8.5 11.749 8.5C11.6772 8.5 11.6125 8.53028 11.5671 8.57874M11.9119 8.55972L11.749 8.95312L11.5671 8.57874M11.5671 8.57874C11.5251 8.62349 11.4995 8.68375 11.5 8.75C11.5 8.79461 11.5117 8.8365 11.5322 8.87276M11.5322 8.87276C11.5751 8.9487 11.6566 9 11.75 9C11.8434 9 11.9249 8.9487 11.9678 8.87276M11.5322 8.87276L11.75 8.75L11.9678 8.87276M12 21.5C7.029 21.5 3 17.471 3 12.5C3 7.529 7.029 3.5 12 3.5C16.971 3.5 21 7.529 21 12.5C21 17.471 16.971 21.5 12 21.5Z"
              stroke="#EC4E4E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p class="info__text">
          По&nbsp;нашей статистике&nbsp;90% гостей берут на&nbsp;себя
          транзакционные издержки
        </p>
      </div>
    </div>

    <div
      v-show="cardModal"
      class="cardModal"
    >
      <div
        class="cardModal__overlay"
        @click="cardModal = false"
      />
      <div class="cardModal__content">
        <div class="cardModal__title">
          <vCloseButton @click="cardModal = false" />
        </div>
        <div class="cardModal__container">
          <vInput
            id="modalCard"
            v-model="modal.cardNumber"
            :value="modal.cardNumber"
            placeholder="Номер карты"
            :required="true"
            :error="validation.cardNumber"
            mask="#### #### #### ####"
            type="text"
          />

          <div class="cardModal__row">
            <vInput
              id="modalDate"
              v-model="modal.date"
              :value="modal.date"
              placeholder="ММ/ГГ"
              mask="##/##"
              :required="true"
              type="text"
            />
            <vInput
              id="modalCvv"
              v-model="modal.cvv"
              :value="modal.cvv"
              placeholder="CVV"
              :required="true"
              mask="###"
              type="number"
            />
          </div>

          <vButton
            type="button"
            :disabled="
              modal.cardNumber.length !== 19 ||
                modal.date.length !== 5 ||
                modal.cvv.length !== 3 ||
                loading
            "
            @click="cardVerification"
          >
            Привязать карту
          </vButton>
        </div>
      </div>
    </div>

    <div
      v-show="deleteCardModal"
      class="cardModal"
    >
      <div
        class="cardModal__overlay"
        @click="deleteCardModal = false"
      />
      <div class="cardModal__content">
        <div class="cardModal__title">
          Предупреждение
          <vCloseButton @click="deleteCardModal = false" />
        </div>
        <div class="cardModal__container deleteCardModal__container">
          <div class="deleteCardModal__text">
            Вы действительно хотите удалить карту?
          </div>

          <div class="deleteCardModal__row">
            <div class="deleteCardModal__button">
              <vButton
                type="button"
                @click="deleteCard"
              >
                Удалить
              </vButton>
            </div>
            <div class="deleteCardModal__button">
              <vButton
                type="button"
                theme="gray"
                @click="deleteCardModal = false"
              >
                Отменить
              </vButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'
import VAlertMessage from '@/components/v-alert-message'
import vInput from '@/components/v-input'
import VButton from '@/components/v-button'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import VCloseButton from '@/components/v-close-button'
import axios from 'axios'

export default {
  name: 'VSectionCloud',
  components: {
    VCloseButton,
    VButton,
    VAlertMessage,
    vInput,
    Form,
  },
  data() {
    return {
      loading: false,
      phone: '',
      errorMessage: '',
      cardModal: false,
      deleteCardModal: false,
      isSavedPhone: false,
      validation: {
        PhoneNumber: '',
        cardNumber: '',
      },

      modal: {
        cardNumber: '',
        date: '',
        cvv: '',
      },
    }
  },

  computed: {
    ...mapGetters('user', [
      'GET_AUTHENTIFICATED',
      'USER_DATA',
      'USER_ERROR',
      'CLOUD_DATA',
    ]),
    ...mapGetters('shops', ['SHOP_ERROR']),

    hasNumberCard() {
      return this.CLOUD_DATA?.card_last_four || false
    },
  },

  async mounted() {
    this.TOGGLE_PRELOADER(true)
    await this.GET_USER_DATA(this.GET_AUTHENTIFICATED)
    const token = this.GET_AUTHENTIFICATED

    let checkoutScript = document.createElement('script')
    checkoutScript.setAttribute(
      'src',
      'https://checkout.cloudpayments.ru/checkout.js'
    )
    document.head.appendChild(checkoutScript)

    if (
      this.$router.currentRoute.value.query?.MD &&
      this.$router.currentRoute.value.query?.PaRes
    ) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/p2p/cloudtips/cards/verification`,
          {
            MD: this.$router.currentRoute.value.query?.MD,
            PaRes: this.$router.currentRoute.value.query?.PaRes,
          },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
      } catch (e) {
        console.log('Verification error: ', e)
      } finally {
        await this.$router.replace({ query: {} })
      }
    }
    await this.fetchDataVerification()

    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },

  unmounted() {
    this.isSavedPhone = false
    this.phone = ''
  },

  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('user', ['GET_USER_DATA', 'GET_CLOUD_DATA']),

    async checkForm() {
      return null
    },

    async fetchDataVerification(initial = true) {
      await this.GET_CLOUD_DATA(await this.GET_AUTHENTIFICATED)

      if (initial) this.phone = this.CLOUD_DATA.phone

      if (this.CLOUD_DATA.phone) {
        this.isSavedPhone = true
      }
    },

    async savePhone() {
      try {
        this.TOGGLE_PRELOADER(true)
        this.setInitialErrors()
        const token = this.GET_AUTHENTIFICATED

        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/p2p/cloudtips/waiter/save-phone`,
          {
            phone: `+7${convertPhoneFormat(this.phone).slice(1)}`,
          },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )

        if (response.data.error) {
          this.validation.PhoneNumber = response.data.message
        }

        await this.fetchDataVerification(false)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.TOGGLE_PRELOADER(false)
        }, 500)
      }
    },

    async cardVerification() {
      this.loading = true
      this.TOGGLE_PRELOADER(true)

      try {
        const token = this.GET_AUTHENTIFICATED

        const checkout = new cp.Checkout({
          publicId: 'pk_5bd1a885126392fe9b3cc0394b2b0',
        })

        const fieldValues = {
          cvv: this.modal.cvv,
          cardNumber: this.modal.cardNumber,
          expDateMonth: this.modal.date.slice(0, 2),
          expDateYear: this.modal.date.slice(3),
        }
        let cryptogramOutput = null

        await checkout
          .createPaymentCryptogram(fieldValues)
          .then((cryptogram) => {
            cryptogramOutput = cryptogram
          })
          .catch((errors) => {
            console.log(errors)
            this.validation.cardNumber = errors?.cardNumber
              ? 'Неверный номер карты'
              : ''
          })

        if (cryptogramOutput) {
          const responseCard = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/p2p/cloudtips/cards/auth`,
            {
              CardCryptogramPacket: cryptogramOutput,
            },
            {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }
          )

          if (responseCard.data.succeed === 'Need3ds') {
            this.send3ds(responseCard.data.acsUrl, {
              MD: responseCard.data.md,
              PaReq: responseCard.data.paReq,
              TermUrl: `${process.env.VUE_APP_BASE_URL}/p2p/cloudtips/cards/verification`,
            })
          } else if (responseCard.data.succeed) {
            await this.fetchDataVerification(false)
          }

          await this.fetchDataVerification(false)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.TOGGLE_PRELOADER(false)
          this.loading = false
        }, 500)
      }
    },
    send3ds(path, params) {
      const method = 'post'

      var form = document.createElement('form')
      form.setAttribute('method', method)
      form.setAttribute('action', path)

      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          var hiddenField = document.createElement('input')
          hiddenField.setAttribute('type', 'hidden')
          hiddenField.setAttribute('name', key)
          hiddenField.setAttribute('value', params[key])

          form.appendChild(hiddenField)
        }
      }

      document.body.appendChild(form)
      form.submit()
    },
    setInitialErrors() {
      this.validation.PhoneNumber = ''
      this.validation.cardNumber = ''
    },
    async deleteCard() {
      this.TOGGLE_PRELOADER(true)
      const token = this.GET_AUTHENTIFICATED

      try {
        this.deleteCardModal = false
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/p2p/cloudtips/cards/delete`,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )

        await this.fetchDataVerification()
      } catch (e) {
        console.log('delete card error: ', e)
      } finally {
        setTimeout(() => {
          this.TOGGLE_PRELOADER(false)
        }, 500)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.section-cloud {
  &__card-wrap {
    display: flex;
    align-items: center;
    padding: 14px 12px;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    background: #f7f8fa;
  }

  &__card-icon {
    margin-right: 10px;
  }

  &__card-text {
    margin-right: auto;
  }

  &__card-delete {
    color: #ec4e4e;
    cursor: pointer;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    font-size: 15px;
    color: #343d5d;
    border: 1px solid #e6e7eb;
    border-radius: 11px;
  }

  &__key {
    text-align: left;
    margin-right: 10px;
  }

  &__value {
    flex-shrink: 0;
    padding: 4px;
    font-size: 16px;
    color: #010d35;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    background: #f8f9fe;
  }

  &__form {
    margin-top: 32px;
  }

  &__legend {
    max-width: 300px;
    width: 100%;

    @media screen and (max-width: 1023px) {
      display: block;
      position: relative;
      left: 0;
      width: 100%;
      margin-bottom: 16px;

      h3 {
        margin-bottom: 4px;
      }
    }
  }
}

.info {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  margin-bottom: 24px;
  border: 1px solid #6764ff;
  border-radius: 11px;
  font-family: PTRootUI, sans-serif;
  font-size: 12px;
  font-weight: 500;

  &__icon {
    display: flex;
    flex-shrink: 0;
  }

  &__text {
    margin: 0 12px 0 8px;
    text-align: left;
  }
}

.cardModal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    background: rgba(220, 222, 227, 0.9);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  &__content {
    border-radius: 11px;
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 24px 16px;
    width: 100%;
    max-width: 718px;
    @media (max-width: 900px) {
      max-width: 368px;
      overflow: auto;
    }
  }
  &__row {
    display: flex;
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    margin-bottom: 14px;
  }
  &__container {
    padding: 3.6rem 4rem;
    @media (max-width: 900px) {
      padding: 1.6rem 1rem;
    }
  }
}

.deleteCardModal {
  &__title {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 25px;
  }

  &__text {
    margin-bottom: 24px;
    font-size: 17px;
    text-align: center;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    width: calc(50% - 4px);
  }

  &__container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
